import React from "react"
import Layout from "../Components/Layout"
import Contact from "../Components/Contact"

const kontakt = () => {
  return (
    <Layout>
      <Contact />
    </Layout>
  )
}

export default kontakt
