import React, { useState } from "react"
import "../styles/contact.css"
import { sendMessage } from "../utils/clientApi"

const Contact = () => {
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("Offert")
  const [message, setMessage] = useState("")
  const onSubmit = e => {
    e.preventDefault()
    setSending(true)

    const data = { name, phone, email, subject, message }
    sendMessage(data).then(res => {
      setName("")
      setPhone("")
      setEmail("")
      setSubject("Offert")
      setMessage("")
      if (res?.status === 200) {
        setSending(false)
        setSent(true)
        setError(false)
      } else {
        setSending(false)
        setSent(false)
        setError(true)
      }
    })
  }

  return (
    <div className={"contact-wrapper"}>
      <h3>Kontakta oss</h3>
      <form className={"form-wrapper"} onSubmit={onSubmit}>
        <span className={"form-line"}>
          <div className={"form-line__selector"}>
            <p>Namn:</p>
            <input
              name="name"
              placeholder="(Obligatoriskt)"
              value={name}
              onInput={e => setName(e.target.value)}
              required={true}
              disabled={sending || sent}
            />
          </div>
        </span>
        <span className={"form-line"}>
          <div className={"form-line__selector"}>
            <p>Telefonnummer:</p>
            <input
              name="phone"
              value={phone}
              onInput={e => setPhone(e.target.value)}
              disabled={sending || sent}
            />
          </div>
        </span>
        <span className={"form-line"}>
          <div className={"form-line__selector"}>
            <p>Epost:</p>
            <input
              name="email"
              placeholder="(Obligatoriskt)"
              value={email}
              onInput={e => setEmail(e.target.value)}
              required={true}
              disabled={sending || sent}
            />
          </div>
        </span>
        <span className={"form-line"}>
          <div className={"form-line__selector"}>
            <p>Ämne:</p>
            <select
              disabled={sending || sent}
              name="subject"
              onInput={e => setSubject(e.target.value)}
            >
              <option value="Offert">Offertförfrågan</option>
              <option value="Fråga">Fråga</option>
              <option value="Annat">Annat</option>
            </select>
          </div>
        </span>
        <span className={"form-line"}>
          <div className={"form-line__selector"}>
            <p>Meddelande:</p>
            <textarea
              disabled={sending || sent}
              name="message"
              placeholder="(Obligatoriskt)"
              value={message}
              required={true}
              onInput={e => setMessage(e.target.value)}
            />
          </div>
        </span>

        <input
          type="submit"
          value={
            sending ? "Skickar..." : sent ? "Meddelandet är skickat" : "Skicka"
          }
          disabled={sending || sent}
        />
      </form>

      {sent && (
        <div className={"contact-body"}>
          Vi besvarar ditt meddelande så snart vi kan, tack för att du kontaktar
          oss!
        </div>
      )}
      {error && (
        <div className={"contact-body"}>
          Något gick fel, ladda om sidan och försök igen om en stund!
        </div>
      )}
    </div>
  )
}

export default Contact
