import axios from "axios"

export const sendMessage = async data => {
  const baseUrl = `${document?.location?.origin}/api`
  try {
    const response = await axios.post(`${baseUrl}/contact`, {
      email: data.email ? data.email : "error: Email missing",
      message: data.message ? data.message : "error: Message missing",
      name: data.name ? data.name : "error: Name missing",
      phone: data.phone ? data.phone : "error: Phone missing",
      subject: data.subject ? data.subject : "error: Subject missing",
      nm_key: "vcUAjVp95CuwgY3pKXe4gqqFHx3jJKcc",
    })
    return response
  } catch (error) {
    console.warn(error)
  }
}
